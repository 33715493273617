import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { TemplateComponentVariable } from '../model/template-component-variable';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('TemplateComponentVariablesService');
export class TemplateComponentVariablesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.templateComponentVariablesLastCallError = false;
    }
    findTemplateComponentVariables() {
        if (this.templateComponentVariables && !this.templateComponentVariablesLastCallError) {
            logger.debug('findTemplateProductLines: Cache hit.');
        }
        this.templateComponentVariables = (this.templateComponentVariables && !this.templateComponentVariablesLastCallError) ?
            this.templateComponentVariables : this.fetchTemplateComponentVariables();
        return this.templateComponentVariables;
    }
    fetchTemplateComponentVariables() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplateComponentVariables)
            .pipe(map(response => {
            const variables = [];
            for (const item of response['data']) {
                const variable = new TemplateComponentVariable(item.template_component_variable_id, item.display_label, item.format, item.sample_content, item.sample_content, item.product_line, item.source);
                variables.push(variable);
            }
            logger.debug(`${variables.length} variables fetched from REST`);
            this.templateComponentVariablesLastCallError = false;
            return variables;
        }), catchError((err) => {
            this.templateComponentVariablesLastCallError = true;
            const message = `In TemplateComponentVariablesService.findTemplateComponentVariables().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function TemplateComponentVariablesService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemplateComponentVariablesService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplateComponentVariablesService, factory: TemplateComponentVariablesService.ɵfac, providedIn: 'root' }); }
}
