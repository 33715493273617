import { EventEmitter } from '@angular/core';
import { TemplatesService } from '../../../../services/templates.service';
import { checkBreakTag } from '@/app/components/common/common';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/tooltip";
import * as i4 from "../../../../text-diff/ngx-text-diff.component";
function BulkEditChangeDiffComponent_div_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11)(1, "p", 13);
    i0.ɵɵelement(2, "i", 14);
    i0.ɵɵtext(3, "\u00A0");
    i0.ɵɵelementStart(4, "strong");
    i0.ɵɵtext(5, "Warning:");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(6, " the content in the database has changed since you downloaded the file you are now uploading. Verify you are not overwriting changes to content you wish to keep before finalizing the upload OR download a fresh file, make your desired changes, and then upload.");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "p", 13);
    i0.ɵɵelement(8, "i", 14);
    i0.ɵɵtext(9, "\u00A0");
    i0.ɵɵelementStart(10, "strong");
    i0.ɵɵtext(11, "Warning:");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(12, " All single quotes will be always change to double quotes.");
    i0.ɵɵelementEnd()();
} }
export class BulkEditChangeDiffComponent {
    constructor(toastr) {
        this.toastr = toastr;
        this.checkBreakTagCondition = new EventEmitter();
        this.saveBreakTag = true;
        this.setSaveButton = [];
    }
    ngOnInit() {
        if (this.record.deliveryPath !== "EMAIL" &&
            this.record.deliveryPath !== "SMS") {
            const brekTag = checkBreakTag([...this.record.newContent.content.matchAll(/(?=strength)(.*?)(?=>)|(?=time)(.*?)(?=>)/g)], this.saveBreakTag, this.toastr);
            if (brekTag.every((breakTag) => breakTag.checkBreakTag)) {
                brekTag.forEach((breakTag) => (this.record.newContent.content =
                    this.record.newContent.content.replaceAll(breakTag.text, breakTag.text.replace(/'/g, '"'))));
                this.checkBreakTagCondition.emit(false);
            }
            else {
                this.checkBreakTagCondition.emit(true);
            }
        }
    }
    transformHTMLText(htmlText) {
        return htmlText.replace(/(?:\r\n|\r|\n)/g, '<br/>').replace(/(<br\s*[\/]?>)/ig, '¶$1');
    }
    getLocaleTooltip(localeName) {
        const found = this.locales.find(locale => locale.localeName === localeName);
        return found && found.displayLabels && found.displayLabels.en_US ? found.displayLabels.en_US : localeName;
    }
    getOperatorProgramProductTooltip(productId) {
        const found = this.products.find(product => product.id === productId);
        return TemplatesService.getOperatorProgramProductDisplayLabel(found);
    }
    getProductDisplayLabel(productId) {
        return this.products.find(product => product.id === productId).displayLabel;
    }
    static { this.ɵfac = function BulkEditChangeDiffComponent_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || BulkEditChangeDiffComponent)(i0.ɵɵdirectiveInject(i1.ToastrService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: BulkEditChangeDiffComponent, selectors: [["app-bulk-edit-change-diff"]], inputs: { locales: "locales", localeSel: "localeSel", products: "products", productSel: "productSel", record: "record", index: "index" }, outputs: { checkBreakTagCondition: "checkBreakTagCondition" }, decls: 31, vars: 52, consts: [[3, "id"], [2, "float", "right"], [2, "margin", "0 0.5em 0 0.5em", 3, "id", "innerHTML"], ["class", "row", 4, "ngIf"], [1, "row", "padding-bottom-lg"], [1, "w-100"], [1, "padding-bottom-sm", "padding-top-sm", "col-md-6", "vl"], [1, "container-fluid"], [1, "row", 3, "id", "innerHTML"], [1, "col-md-6"], [2, "float", "right", "margin", "0 0.5em 0 0.5em", 3, "id", "innerHTML", "tooltip"], [1, "row"], ["outerContainerClass", "NgxDiffOuterContainer", "toolbarClass", "NgxDiffToolbar", "compareRowsClass", "NgxDiffCompareRows", 3, "id", "left", "right", "showBtnToolbar", "format", "loading"], [1, "bg-warning", "padding-sm", "round-border"], [1, "fas", "fa-exclamation-triangle", "fa-lg", 2, "color", "#FF9900"]], template: function BulkEditChangeDiffComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div")(1, "div", 0)(2, "span", 1);
            i0.ɵɵelement(3, "span", 2)(4, "span", 2);
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "h3", 0);
            i0.ɵɵtext(6);
            i0.ɵɵelementEnd();
            i0.ɵɵelement(7, "hr");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(8, "div", 0);
            i0.ɵɵtemplate(9, BulkEditChangeDiffComponent_div_9_Template, 13, 0, "div", 3);
            i0.ɵɵelementStart(10, "div", 4)(11, "table", 5)(12, "tbody")(13, "tr")(14, "td", 6)(15, "div", 7);
            i0.ɵɵelement(16, "div", 8);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(17, "td", 9)(18, "div", 7);
            i0.ɵɵelement(19, "div", 8);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(20, "tr")(21, "td", 9)(22, "div", 7);
            i0.ɵɵelement(23, "span", 10)(24, "span", 10);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(25, "td", 9)(26, "div", 7);
            i0.ɵɵelement(27, "span", 10)(28, "span", 10);
            i0.ɵɵelementEnd()()()()()();
            i0.ɵɵelementStart(29, "div", 11);
            i0.ɵɵelement(30, "td-ngx-text-diff", 12);
            i0.ɵɵelementEnd()()();
        } if (rf & 2) {
            i0.ɵɵclassMap(ctx.record.type + " " + ctx.record.currentComponent.deliveryPath.code);
            i0.ɵɵadvance();
            i0.ɵɵclassMap(ctx.record.type + " " + ctx.record.currentComponent.deliveryPath.code);
            i0.ɵɵproperty("id", "bulkEditCheckHeader_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index);
            i0.ɵɵadvance(2);
            i0.ɵɵclassMap("badge-label product-line-label " + ctx.record.currentComponent.productLine);
            i0.ɵɵproperty("id", "productLine_" + ctx.record.type + "_" + ctx.record.currentComponent.productLine + "_" + ctx.index)("innerHTML", ctx.record.currentComponent.productLine, i0.ɵɵsanitizeHtml);
            i0.ɵɵadvance();
            i0.ɵɵclassMap("badge-label delivery-path-label " + ctx.record.currentComponent.deliveryPath.code);
            i0.ɵɵproperty("id", "deliveryPath_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.record.currentComponent.deliveryPath.code, i0.ɵɵsanitizeHtml);
            i0.ɵɵadvance();
            i0.ɵɵproperty("id", "recordTitle_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index);
            i0.ɵɵadvance();
            i0.ɵɵtextInterpolate4(" ", ctx.record.type, " #", ctx.index + 1, ": ", ctx.record.currentComponent.displayLabel, " (", ctx.record.currentComponent.name, ") ");
            i0.ɵɵadvance(2);
            i0.ɵɵclassMap("panel-body " + ctx.record.type + " " + ctx.record.currentComponent.deliveryPath.code);
            i0.ɵɵproperty("id", "bulkEditCheckBody_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", ctx.record.isObsoleteOldContent());
            i0.ɵɵadvance(7);
            i0.ɵɵproperty("id", "currentText_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.transformHTMLText(ctx.record.currentContent.content), i0.ɵɵsanitizeHtml);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("id", "newText_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.transformHTMLText(ctx.record.newContent.content), i0.ɵɵsanitizeHtml);
            i0.ɵɵadvance(4);
            i0.ɵɵclassMap("badge-label locale-label " + ctx.record.currentContent.locale);
            i0.ɵɵproperty("id", "currentLocale_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.record.currentContent.locale, i0.ɵɵsanitizeHtml)("tooltip", ctx.getLocaleTooltip(ctx.record.currentContent.locale));
            i0.ɵɵadvance();
            i0.ɵɵclassMap("badge-label product-label " + (ctx.record.currentContent.productId || "DEFAULT"));
            i0.ɵɵproperty("id", "currentProgram_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.getProductDisplayLabel(ctx.record.currentContent.productId), i0.ɵɵsanitizeHtml)("tooltip", ctx.getOperatorProgramProductTooltip(ctx.record.currentContent.productId));
            i0.ɵɵadvance(3);
            i0.ɵɵclassMap("badge-label locale-label " + ctx.record.newContent.locale);
            i0.ɵɵproperty("id", "newLocale_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.record.newContent.locale, i0.ɵɵsanitizeHtml)("tooltip", ctx.getLocaleTooltip(ctx.record.newContent.locale));
            i0.ɵɵadvance();
            i0.ɵɵclassMap("badge-label product-label " + (ctx.record.newContent.productId || "DEFAULT"));
            i0.ɵɵproperty("id", "newProgram_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("innerHTML", ctx.getProductDisplayLabel(ctx.record.newContent.productId), i0.ɵɵsanitizeHtml)("tooltip", ctx.getOperatorProgramProductTooltip(ctx.record.newContent.productId));
            i0.ɵɵadvance(2);
            i0.ɵɵproperty("id", "textDiff_" + ctx.record.type + "_" + ctx.record.currentComponent.deliveryPath.code + "_" + ctx.index)("left", ctx.record.currentContent.content)("right", ctx.record.newContent.content)("showBtnToolbar", true)("format", "LineByLine")("loading", true);
        } }, dependencies: [i2.NgIf, i3.TooltipDirective, i4.NgxTextDiffComponent], styles: [".modal.fade.show[_ngcontent-%COMP%] {\n  opacity: 1;\n}"] }); }
}
