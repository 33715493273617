import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class ModalComponent {
    constructor() {
        this.isOpenModalClearAll = new EventEmitter();
        this.customHeader = true;
        this.modalTitle = 'modal title';
        this.handleOpenClose = (event) => {
            this.isOpenModalClearAll.emit(event);
        };
        this.applyModal = () => {
            this.isOpenModalClearAll.emit({ status: false, value: 'apply' });
        };
    }
    static { this.ɵfac = function ModalComponent_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ModalComponent)(); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ModalComponent, selectors: [["app-modal"]], inputs: { openModalClearAll: "openModalClearAll" }, outputs: { isOpenModalClearAll: "isOpenModalClearAll" }, decls: 8, vars: 4, consts: [["title-label", "modalTitle", "subtitle-label", "Modal subtitle", "confirm-button-label", "Confirm", "cancel-button-label", "Cancel", "custom-header", "", "z-index", "1", "width", "650px", "closes-on-apply", "", "background-overlay-hidden", "", 3, "isOpen", "cancelButtonInteractionFunction", "preCloseButtonInteractionFunction", "applyButtonInteractionFunction"], ["slot", "header"], [1, "fs-1"], [1, "row"], ["library", "ionicons", "name", "warning-outline", "size", "25px", 1, "mr-2", "col-1"], [1, "mr-2", "col-11"]], template: function ModalComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "dsy-modal", 0)(1, "div", 1)(2, "p", 2);
            i0.ɵɵtext(3, "Template priority");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(4, "div", 3);
            i0.ɵɵelement(5, "dsy-icon", 4);
            i0.ɵɵelementStart(6, "span", 5);
            i0.ɵɵtext(7, "All priorities on main template will be set to \u201Clow\u201D and the priorities based on event type will be set to \u201Cnot assigned\u201D. Are you sure?");
            i0.ɵɵelementEnd()()()();
        } if (rf & 2) {
            i0.ɵɵproperty("isOpen", ctx.openModalClearAll)("cancelButtonInteractionFunction", ctx.handleOpenClose)("preCloseButtonInteractionFunction", ctx.handleOpenClose)("applyButtonInteractionFunction", ctx.applyModal);
        } } }); }
}
