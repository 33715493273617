export class AuditBulkEditTemplateComponentContent {
    static parse(json) {
        return new AuditBulkEditTemplateComponentContent(json.audit_bulk_edit_tcc_id, new Date(Date.parse(json.start_datetime)), new Date(Date.parse(json.end_datetime)), json.user_id, json.locale, json.product_id, json.filename, json.username, json.productName, json.oldContent, json.newContent);
    }
    static create(bes, user) {
        return new AuditBulkEditTemplateComponentContent(null, undefined, null, user.userId, bes.locale.localeName, bes.product.id, bes.file.name, user.username, bes.product.displayLabel);
    }
    constructor(id, start, end, userId, locale, product, filename, username, productName, oldContent, newContent) {
        this.id = id;
        this.start = start;
        this.end = end;
        this.userId = userId;
        this.locale = locale;
        this.product = product;
        this.filename = filename;
        this.username = username;
        this.productName = productName;
        this.oldContent = oldContent;
        this.newContent = newContent;
    }
    toJSON() {
        const obj = {};
        for (const prop in this) {
            switch (prop) {
                case 'id':
                    obj['audit_bulk_edit_tcc_id'] = this[prop];
                    break;
                case 'start':
                case 'end':
                    obj[prop + '_datetime'] = (isNaN(+this[prop]) || +this[prop] <= 0) ? this[prop] : this[prop].toISOString();
                    break;
                case 'userId':
                    obj['user_id'] = this[prop];
                    break;
                case 'product':
                    obj['product_id'] = this[prop];
                    break;
                default:
                    obj[prop.toString()] = this[prop];
                    break;
            }
        }
        return obj;
    }
}
