import { factory } from "../shared/app-logging";
import { BehaviorSubject, catchError, map, throwError } from "rxjs";
import { environment } from "@/environments/environment";
import { AppConfig } from "../shared/app-config";
import { ErrorDataService } from "./error-data.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('ExportService');
export class ExportService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.openExportModal = new BehaviorSubject(false);
        this.isExportEnabled = new BehaviorSubject(false);
        this.isLoadingActive = new BehaviorSubject(false);
    }
    setOpenExportModal(value) {
        this.openExportModal.next(value);
    }
    getOpenExportModal() {
        return this.openExportModal;
    }
    setIsExportEnabled(value) {
        this.isExportEnabled.next(value);
    }
    getIsExportEnabled() {
        return this.isExportEnabled;
    }
    setIsLoadingActive(value) {
        this.isLoadingActive.next(value);
    }
    getIsLoadingActive() {
        return this.isLoadingActive;
    }
    exportTemplatePriority(request) {
        return this.httpClient.post(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplatePriorityExport, request)
            .pipe(map((response) => {
            if (response.code != 200) {
                const errorMessage = 'An unexpected error occurred exporting TemplatePriorities: ' + response.message;
                logger.error(errorMessage);
                // const message = `In ExportService.exportTemplatePriority().`;
                // return throwError(ErrorDataService.overLoadError(new Error(errorMessage), message));
                response.code = 500;
            }
            return response;
        }), catchError((err) => {
            const message = `In ExportService.exportTemplatePriority().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    static { this.ɵfac = function ExportService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ExportService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ExportService, factory: ExportService.ɵfac, providedIn: 'root' }); }
}
