import { catchError, forkJoin, of } from 'rxjs';
import { factory } from '@/app/shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "@/app/services/template-priority.service";
import * as i2 from "@/app/services/user.service";
import * as i3 from "@angular/common";
import * as i4 from "../loader/loader.component";
import * as i5 from "./priority-switcher/priority-switcher.component";
import * as i6 from "../modal/modal.component";
const _c0 = () => ({ "color": "#5738FF", "cursor": "pointer", "font-weight": "bold" });
function TemplatePriorityTemplatesComponent_span_9_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 12);
    i0.ɵɵlistener("click", function TemplatePriorityTemplatesComponent_span_9_Template_span_click_0_listener() { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.openModalClearAll()); });
    i0.ɵɵtext(1, "CLEAR ALL ");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵproperty("ngStyle", i0.ɵɵpureFunction0(1, _c0));
} }
function TemplatePriorityTemplatesComponent_app_template_priority_loader_12_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "app-template-priority-loader", 13);
} if (rf & 2) {
    i0.ɵɵproperty("displayColumns", 12);
} }
function TemplatePriorityTemplatesComponent_div_13_div_1_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15)(1, "app-template-priority-switcher", 16);
    i0.ɵɵlistener("update", function TemplatePriorityTemplatesComponent_div_13_div_1_Template_app_template_priority_switcher_update_1_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r1 = i0.ɵɵnextContext(2); return i0.ɵɵresetView(ctx_r1.onUpdateTemplatePriority($event)); });
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const templatePriority_r4 = ctx.$implicit;
    const i_r5 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("templatePriority", templatePriority_r4)("index", i_r5)("editEnabled", ctx_r1.editEnabled && !ctx_r1.isLoadingActive)("eventTypes", ctx_r1.eventTypes)("lengthByScreen", ctx_r1.lengthByScreen)("dropdownSelectedEntries", ctx_r1.dropdownSelectedEntries);
} }
function TemplatePriorityTemplatesComponent_div_13_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, TemplatePriorityTemplatesComponent_div_13_div_1_Template, 2, 6, "div", 14);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.editedTemplatePriorities);
} }
const logger = factory.getLogger('TemplatePriorityTemplatesComponent');
export class TemplatePriorityTemplatesComponent {
    constructor(templatePriorityService, userService) {
        this.templatePriorityService = templatePriorityService;
        this.userService = userService;
        this.subscriptions$ = [];
        this.timeoutIds$ = [];
        this.lengthByScreen = 4;
        this.classFilter = "visually-hidden";
        this.eventTypes = [];
        this.eventTypeCodes = [];
        this.templates = [];
        this.templateNames = [];
        this.defaultTemplatePriorities = [];
        this.templatePriorities = [];
        this.editedTemplatePriorities = [];
        this.templatesListIsLoading = false;
        this.editEnabled = false;
        this.isLoadingActive = false;
        this.openModalClearAllStatus = false;
        this.dropdownSelectedEntries = [];
        this.templatePriorityLogInfo = {
            username: '',
            user_id: '',
            template_name: '',
            old_priority: '',
            new_priority: '',
        };
    }
    ngOnInit() {
        // subscribe to template priority state changes
        this.subscriptions$.push(this.templatePriorityService.getAction().subscribe((value) => {
            if (value === 'EDIT') {
                this.editEnabled = true;
            }
            if (value === 'CANCEL') {
                this.templatePriorityService.setTemplatePriorities(this.templatePriorities);
                this.editedTemplatePriorities = JSON.parse(JSON.stringify(this.templatePriorities));
                this.templatePriorityService.setEditedTemplatePriorities(this.editedTemplatePriorities);
                this.editEnabled = false;
            }
            if (value === 'SAVE_OK') {
                this.templatePriorities = JSON.parse(JSON.stringify(this.editedTemplatePriorities));
                this.templatePriorityService.setTemplatePriorities(this.templatePriorities);
                this.editEnabled = false;
            }
        }));
        // subscribe to template priority isLoadingActive changes
        this.subscriptions$.push(this.templatePriorityService.getIsLoadingActive().subscribe((value) => {
            this.isLoadingActive = value;
        }));
        // subscribe to template priority selected OPPs changes
        this.subscriptions$.push(this.templatePriorityService.getDropdownSelectedEntries().subscribe((value) => {
            if (JSON.stringify(this.dropdownSelectedEntries) !== JSON.stringify(value)) {
                this.dropdownSelectedEntries = value;
                if (!this.dropdownSelectedEntries || this.dropdownSelectedEntries.length == 0
                    || (this.dropdownSelectedEntries.length == 1 && (this.dropdownSelectedEntries[0].product?.id == null || this.dropdownSelectedEntries[0].product?.id == 'ALL'))
                    || (!this.defaultTemplatePriorities || this.defaultTemplatePriorities.length == 0)) {
                    // RESET to default priorities
                    this.templatePriorities = JSON.parse(JSON.stringify(this.defaultTemplatePriorities));
                    this.editedTemplatePriorities = JSON.parse(JSON.stringify(this.defaultTemplatePriorities));
                    this.templatePriorityService.setTemplatePriorities(this.templatePriorities);
                    this.templatePriorityService.setEditedTemplatePriorities(this.editedTemplatePriorities);
                    this._retrieveTemplatePriorities();
                }
                else {
                    if (this.dropdownSelectedEntries.length >= 1) {
                        // ASK for custom priorities            
                        // It's not needed to call it again if the end user selects more than one product
                        this._retrieveTemplateCustomPriorities();
                    }
                }
            }
        }));
        this.retrieveEventTypesAndTemplates();
    }
    getUserDetails() {
        this.subscriptions$.push(this.userService.getUserDetailData().subscribe((value) => {
            if (value) {
                if (value.userDetails.user_id && value.userDetails.username) {
                    this.templatePriorityLogInfo.user_id = value.userDetails.user_id;
                    this.templatePriorityLogInfo.username = value.userDetails.username;
                }
            }
        }));
    }
    ngOnDestroy() {
        this.subscriptions$.forEach(sub => sub.unsubscribe());
        this.timeoutIds$.forEach(timeoutId => clearTimeout(timeoutId));
    }
    retrieveEventTypesAndTemplates() {
        logger.info('Retrieving Event Types and Templates...');
        this.templatesListIsLoading = true;
        forkJoin([
            this.templatePriorityService.retrieveEventTypes().pipe(catchError((err) => {
                this.templatePriorityService.setError(err?.error?.message);
                return of(err);
            })),
            this.templatePriorityService.retrieveTemplatesNames().pipe(catchError((err) => {
                this.templatePriorityService.setError(err?.error?.message);
                return of(err);
            })),
        ]).subscribe(([eventTypes, templates]) => {
            if (eventTypes && templates) {
                this.eventTypes = eventTypes;
                this.eventTypeCodes = eventTypes.map((type) => type.code);
                this.templates = templates;
                this.templateNames = templates.map((type) => type.name);
                this._retrieveTemplatePriorities();
            }
        });
    }
    _retrieveTemplatePriorities(product_id) {
        if (this.eventTypeCodes && this.eventTypeCodes.length > 0 && this.templateNames && this.templateNames.length > 0) {
            this.templatesListIsLoading = true;
            this.templatePriorityService.setIsLoadingActive(true);
            this.templatePriorityService
                .retrieveTemplatePriorities(this.eventTypeCodes, this.templates, product_id)
                .pipe(catchError((err) => {
                this.templatePriorityService.setError(err?.error?.message);
                return of(err);
            }))
                .subscribe((response) => {
                if (response && response.length > 0) {
                    if (!product_id) {
                        this.templatePriorityService.getTemplatesWithByEventTypesEnabled().forEach((template_name) => {
                            const itemsForTemplateWithByEventTypesEnabled = response.filter((res) => res.template_name === template_name);
                            if (itemsForTemplateWithByEventTypesEnabled && itemsForTemplateWithByEventTypesEnabled.length > 1) {
                                // remove the items from response
                                response = response.filter((res) => res.template_name !== template_name);
                                // Build one unique item with a customization array
                                const newUniqueTemplatePriority = JSON.parse(JSON.stringify(itemsForTemplateWithByEventTypesEnabled[0]));
                                newUniqueTemplatePriority.customizations = [];
                                newUniqueTemplatePriority.event_types = this.eventTypeCodes;
                                const customizationPriorities = [];
                                itemsForTemplateWithByEventTypesEnabled.forEach((itemForTemplateWithByEventTypesEnabled) => {
                                    itemForTemplateWithByEventTypesEnabled.event_types.forEach((eventType) => {
                                        customizationPriorities.push({
                                            event_type: eventType,
                                            priority: itemForTemplateWithByEventTypesEnabled.priority
                                        });
                                    });
                                });
                                newUniqueTemplatePriority.customizations = customizationPriorities;
                                response.push(newUniqueTemplatePriority);
                            }
                        });
                        response.sort((a, b) => {
                            const nameA = a.template_name;
                            const nameB = b.template_name;
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }
                            return 0;
                        });
                    }
                    this.defaultTemplatePriorities = JSON.parse(JSON.stringify(response.sort((a, b) => a.template_display_label > b.template_display_label ? 1 : -1)));
                    this.templatePriorities = JSON.parse(JSON.stringify(response.sort((a, b) => a.template_display_label > b.template_display_label ? 1 : -1)));
                    this.editedTemplatePriorities = JSON.parse(JSON.stringify(response.sort((a, b) => a.template_display_label > b.template_display_label ? 1 : -1)));
                    this.templatePriorityService.setDefaultTemplatePriorities(this.defaultTemplatePriorities);
                    this.templatePriorityService.setTemplatePriorities(this.templatePriorities);
                    this.templatePriorityService.setEditedTemplatePriorities(this.editedTemplatePriorities);
                    this.templatesListIsLoading = false;
                    this.templatePriorityService.setIsLoadingActive(false);
                }
                else {
                }
            });
        }
    }
    _retrieveTemplateCustomPriorities() {
        const product_ids = this.dropdownSelectedEntries
            .map((dropdownEntry) => dropdownEntry.product.id);
        if (product_ids && product_ids.length > 0 && product_ids[0] && this.eventTypeCodes && this.eventTypeCodes.length > 0 && this.templateNames && this.templateNames.length > 0) {
            // set loading
            this.templatesListIsLoading = true;
            this.templatePriorityService.setIsLoadingActive(true);
            // retrieve template priorities customizations for the selected product
            forkJoin([
                this.templatePriorityService.retrieveTemplatePriorities(this.eventTypeCodes, this.templates, product_ids[0])
                    .pipe(catchError((err) => {
                    this.templatePriorityService.setError(err?.error?.message);
                    return of(err);
                }))
            ]).subscribe(([templatePrioritiesFromServer]) => {
                // reset to default priorities without customizations
                this.templatePriorities = JSON.parse(JSON.stringify(this.defaultTemplatePriorities));
                // reset customizations and event types
                this.templatePriorities.forEach((templatePriority) => {
                    templatePriority.customizations = [];
                    templatePriority.event_types = this.eventTypeCodes;
                });
                // if template priorities found for the selected product
                if (templatePrioritiesFromServer && templatePrioritiesFromServer.length > 0) {
                    // scan template priorities
                    templatePrioritiesFromServer.forEach((templatePriorityFromServer) => {
                        if (!this.templatePriorityService.getTemplatesWithByEventTypesEnabled().includes(templatePriorityFromServer.template_name)) {
                            // if template priority is not configured to have "by event types" enabled
                            // set the priority
                            this.templatePriorities.find((tp) => tp.template_name == templatePriorityFromServer.template_name).priority = templatePriorityFromServer.priority;
                        }
                        else {
                            // if template priority is configured to have "by event types" enabled
                            // create custom priority by event type object for each event type
                            const templatePriority = this.templatePriorities.find((tp) => tp.template_name == templatePriorityFromServer.template_name);
                            // set new values
                            if (templatePriorityFromServer.event_types.length == this.eventTypeCodes.length) {
                                templatePriority.priority = templatePriorityFromServer.priority;
                            }
                            else {
                                templatePriorityFromServer.event_types.forEach((eventType) => {
                                    templatePriority.customizations.push({
                                        event_type: eventType,
                                        priority: templatePriorityFromServer.priority
                                    });
                                });
                            }
                        }
                    });
                }
                // set missing customizations
                this.templatePriorities
                    .filter((templatePriority) => templatePriority.customizations?.length > 0)
                    .forEach((templatePriority) => {
                    if (templatePriority.customizations?.length < this.eventTypeCodes.length) {
                        this.eventTypeCodes
                            .filter((eventType) => !templatePriority.customizations.map((customization) => customization.event_type).includes(eventType))
                            .forEach((eventType) => {
                            templatePriority.customizations.push({
                                event_type: eventType,
                                priority: templatePriority.priority
                            });
                        });
                    }
                });
                this.templatePriorityService.setTemplatePriorities(this.templatePriorities);
                this.editedTemplatePriorities = JSON.parse(JSON.stringify(this.templatePriorities));
                this.templatePriorityService.setEditedTemplatePriorities(this.editedTemplatePriorities);
                this.templatesListIsLoading = false;
                this.templatePriorityService.setIsLoadingActive(false);
            });
        }
    }
    closeModalClearAll(event) {
        if (event) {
            this.templatesListIsLoading = true;
            this.templatePriorityService.setIsLoadingActive(true);
            this.editedTemplatePriorities.forEach((templatePriority) => {
                templatePriority.priority = 'LOW';
                templatePriority.customizations = [];
            });
            this.templatePriorityService.setEditedTemplatePriorities(this.editedTemplatePriorities);
            this.templatePriorityService.setIsSaveEnabled(JSON.stringify(this.templatePriorities) !== JSON.stringify(this.editedTemplatePriorities));
            this.timeoutIds$.push(setTimeout(() => {
                this.templatesListIsLoading = false;
                this.templatePriorityService.setIsLoadingActive(false);
            }, 1000));
        }
        this.openModalClearAllStatus = false;
    }
    openModalClearAll() {
        this.openModalClearAllStatus = true;
    }
    onUpdateTemplatePriority(event) {
        this.templatePriorityLogInfo = {
            username: '',
            user_id: '',
            template_name: '',
            old_priority: '',
            new_priority: '',
        };
        this.getUserDetails();
        this.templatePriorityLogInfo.template_name = event.template_name;
        this.templatePriorityLogInfo.old_priority = event.old_priority;
        this.templatePriorityLogInfo.new_priority = event.priority;
        this.templatePriorityService.loggedTemplatePriorities.update((value) => [...value, this.templatePriorityLogInfo]);
        this.editedTemplatePriorities.forEach((templatePriority) => {
            if (templatePriority.template_name == event.template_name) {
                templatePriority.priority = event.priority;
                templatePriority.customizations = event.customizations;
            }
        });
        this.templatePriorityService.setEditedTemplatePriorities(this.editedTemplatePriorities);
        this.templatePriorityService.setIsSaveEnabled(JSON.stringify(this.templatePriorities) !== JSON.stringify(this.editedTemplatePriorities));
    }
    static { this.ɵfac = function TemplatePriorityTemplatesComponent_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemplatePriorityTemplatesComponent)(i0.ɵɵdirectiveInject(i1.TemplatesPriorityService), i0.ɵɵdirectiveInject(i2.UserService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TemplatePriorityTemplatesComponent, selectors: [["app-template-priority-templates"]], inputs: { lengthByScreen: "lengthByScreen" }, decls: 15, vars: 4, consts: [[1, "d-flex", "justify-content-center"], [1, "card", "card-rounded", "card-shadow", "col-11", "m-3"], [1, "card-title"], [1, "fw-bold", "m-3"], [1, "card-text", "m-3"], [1, "hr", "m-3"], [1, "card-body"], ["class", "position-absolute top-10 end-0 pe-4 fs-5", 3, "ngStyle", "click", 4, "ngIf"], [1, "fw-bold", "demand-response-template-title"], [3, "displayColumns", 4, "ngIf"], [4, "ngIf"], [3, "isOpenModalClearAll", "openModalClearAll"], [1, "position-absolute", "top-10", "end-0", "pe-4", "fs-5", 3, "click", "ngStyle"], [3, "displayColumns"], ["class", "row", 4, "ngFor", "ngForOf"], [1, "row"], [3, "update", "templatePriority", "index", "editEnabled", "eventTypes", "lengthByScreen", "dropdownSelectedEntries"]], template: function TemplatePriorityTemplatesComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div", 0)(1, "div", 1)(2, "div", 2)(3, "h1", 3);
            i0.ɵɵtext(4, "List of Templates");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(5, "p", 4);
            i0.ɵɵtext(6, " The priority assigned to the template applies to all delivery methods, locales, and brands. Enable changes by clicking the Edit button and assign a priority. Click on the Event Type link to open the panel and select a priority based on Event Type. ");
            i0.ɵɵelementEnd()();
            i0.ɵɵelement(7, "hr", 5);
            i0.ɵɵelementStart(8, "div", 6);
            i0.ɵɵtemplate(9, TemplatePriorityTemplatesComponent_span_9_Template, 2, 2, "span", 7);
            i0.ɵɵelementStart(10, "h4", 8);
            i0.ɵɵtext(11, "Demand Response Template:");
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(12, TemplatePriorityTemplatesComponent_app_template_priority_loader_12_Template, 1, 1, "app-template-priority-loader", 9)(13, TemplatePriorityTemplatesComponent_div_13_Template, 2, 1, "div", 10);
            i0.ɵɵelementEnd()()();
            i0.ɵɵelementStart(14, "app-modal", 11);
            i0.ɵɵlistener("isOpenModalClearAll", function TemplatePriorityTemplatesComponent_Template_app_modal_isOpenModalClearAll_14_listener($event) { return ctx.closeModalClearAll($event); });
            i0.ɵɵelementEnd();
        } if (rf & 2) {
            i0.ɵɵadvance(9);
            i0.ɵɵproperty("ngIf", ctx.editEnabled && !ctx.isLoadingActive);
            i0.ɵɵadvance(3);
            i0.ɵɵproperty("ngIf", ctx.templatesListIsLoading);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", !ctx.templatesListIsLoading);
            i0.ɵɵadvance();
            i0.ɵɵproperty("openModalClearAll", ctx.openModalClearAllStatus);
        } }, dependencies: [i3.NgForOf, i3.NgIf, i3.NgStyle, i4.TemplatePriorityLoaderComponent, i5.TemplatePrioritySwitcherComponent, i6.ModalComponent], styles: [".hr[_ngcontent-%COMP%] {\n  border-color: #C2CDDD;\n  opacity: unset !important;\n}\n\n.demand-response-template-title[_ngcontent-%COMP%] {\n  font-size: 20px;\n}\n\n.card-rounded[_ngcontent-%COMP%] {\n  border-radius: 8px;\n}\n\n.card-shadow[_ngcontent-%COMP%] {\n  box-shadow: 0 3px 6px 0 rgba(102, 119, 144, 0.2);\n}"] }); }
}
