import { Template } from '../model/template';
import { ProductLine } from '../model/product-line.enum';
import { TemplateComponent } from '../model/template-component';
import { ComponentType } from '../model/component-type.enum';
import { TemplateDeliveryPath } from '../model/template-delivery-path';
import { TemplateDefinition } from '../model/template-definition';
import { TemplateComponentContent } from '../model/template-component-content';
import { AppConfig } from '../shared/app-config';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import Mustache from 'mustache';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('TemplatesService');
export class TemplatesService {
    static getOperatorProgramProductDisplayLabel(product) {
        return `${product.program.operator.displayLabel} - ${product.program.displayLabel} - ${product.displayLabel}`;
    }
    static getTemplateContextData(inputText, tags, sampleValues) {
        const tokens = Mustache.parse(inputText, tags);
        const contextData = {};
        for (const token of tokens) {
            if (token[0] === 'name') {
                const tcv = sampleValues.find(tcvFilter => tcvFilter.displayLabel === token[1]);
                if (tcv) {
                    contextData[tcv.displayLabel] = tcv.sampleContent;
                }
            }
        }
        return contextData;
    }
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.templatesLastCallError = false;
        this.componentsStructureLastCallError = false;
    }
    findTemplates() {
        if (this.templates && !this.templatesLastCallError) {
            logger.debug('findTemplates: Cache hit.');
        }
        this.templates = (this.templates && !this.templatesLastCallError) ? this.templates : this.fetchTemplates();
        return this.templates;
    }
    fetchTemplates() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplates).pipe(map(response => {
            const templates = [];
            for (const item of response['data']) {
                templates.push(new Template(item['template_name'], item['display_label'], item['product_line']));
            }
            logger.debug(`findTemplates(): ${templates.length} templates found.`);
            this.templatesLastCallError = false;
            return templates;
        }), catchError((err) => {
            const message = `In TemplatesService.findTemplates.`;
            this.templatesLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    findTemplateComponentContents(templateName, deliveryPathCode, localeName, productId) {
        let urlService = AppConfig.urlTempCompContsByTempNameDelPathLocProg;
        urlService = urlService.replace('{template_name}', templateName);
        urlService = urlService.replace('{delivery_path}', deliveryPathCode);
        urlService = urlService.replace('{locale}', localeName);
        urlService = urlService.replace('{product_id}', productId);
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            let template;
            const ti = response['data'];
            template = new Template(ti.template_name, ti.displayLabel, ProductLine[ProductLine[ti.product_line]], []);
            for (const tdi of ti.definitions) {
                const tci = tdi.component;
                const templateComponent = new TemplateComponent(tci.template_component_id);
                templateComponent.name = tci.template_component_name;
                templateComponent.displayLabel = tci.display_label;
                templateComponent.componentType = ComponentType[ComponentType[tci.component_type]];
                templateComponent.deliveryPath = new TemplateDeliveryPath(tci.delivery_path);
                templateComponent.contents = [];
                const td = new TemplateDefinition(tdi.template_definition_id, template, templateComponent, templateComponent.deliveryPath, tdi.display_sequence);
                template.definitions.push(td);
                for (const tcci of tci.contents) {
                    const templateComponentContent = new TemplateComponentContent(tcci.template_component_content_id);
                    templateComponentContent.templateComponent = templateComponent;
                    templateComponentContent.content = tcci.content;
                    templateComponentContent.locale = tcci.locale;
                    templateComponentContent.productId = tcci.product_id;
                    templateComponentContent.sampleContent = tcci.sample_content;
                    templateComponentContent.isDefaultInd = tcci.is_default_ind;
                    templateComponent.contents.push(templateComponentContent);
                }
            }
            logger.debug(`findTemplateComponentContents(${urlService}):  template contents found for ${template.name}.`);
            return template;
        }), catchError((err) => {
            const message = `In TemplatesService.findTemplateComponentContents(${templateName},${deliveryPathCode},${localeName},${productId}).`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    findTemplateComponent(templateComponentId) {
        let urlService = AppConfig.urlTempCompById;
        urlService = urlService.replace('{template_component_id}', templateComponentId.toString());
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            const item = response['data'];
            return new TemplateComponent(item['template_component_id'], item['template_component_name'], item['display_label'], item['component_type'], item['delivery_path']);
        }), catchError((err) => {
            const message = `In TemplatesService.findTemplateComponent(${templateComponentId}).`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    findTemplateComponentContent(templateComponentId, locale) {
        let urlService = AppConfig.urlTempCompContsByCompLoc;
        urlService = urlService.replace('{template_component_id}', templateComponentId.toString());
        urlService = urlService.replace('{locale}', locale);
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            if (response['code'] === 200) {
                return TemplateComponentContent.parse(response['data']);
            }
        }), catchError((err) => {
            const message = `In TemplatesService.findTemplateComponentContent(${templateComponentId}).`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    saveTemplateComponentContent(tcc) {
        const urlService = AppConfig.urlTempCompConts;
        if (tcc.id) {
            return this.httpClient.put(environment.contentLibraryApiBaseUrl + urlService, tcc).pipe(map(response => {
                if (response['code'] === 200) {
                    return TemplateComponentContent.parse(response['data']);
                }
            }), catchError((err) => {
                const message = `In TemplatesService.saveTemplateComponentContent(${tcc}).`;
                return throwError(ErrorDataService.overLoadError(err, message));
            }));
        }
        else {
            return this.httpClient.post(environment.contentLibraryApiBaseUrl + urlService, tcc).pipe(map(response => {
                if (response['code'] === 200) {
                    return TemplateComponentContent.parse(response['data']);
                }
            }), catchError((err) => {
                const message = `In TemplatesService.saveTemplateComponentContent(${tcc}).`;
                return throwError(ErrorDataService.overLoadError(err, message));
            }));
        }
    }
    removeTemplateComponentContent(templateComponentContentId) {
        let urlService = AppConfig.urlTempCompContsById;
        urlService = urlService.replace('{template_component_content_id}', templateComponentContentId.toString());
        return this.httpClient.delete(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            if (response['code'] === 200) {
                return TemplateComponentContent.parse(response['data']);
            }
        }), catchError((err) => {
            return throwError(err);
        }), catchError((err) => {
            const message = `In TemplatesService.removeTemplateComponentContent(${templateComponentContentId}).`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    getComponentsStructure() {
        if (this.componentsStructure && !this.componentsStructureLastCallError) {
            logger.debug('getComponentsStructure: Cache hit.');
        }
        this.componentsStructure = (this.componentsStructure && !this.componentsStructureLastCallError) ?
            this.componentsStructure : this.findComponentsContents('null', 'null');
        return this.componentsStructure;
    }
    findComponentsContents(localeName, productId) {
        let urlService = AppConfig.urlCompsContsByLocProg;
        urlService = urlService.replace('{locale}', localeName);
        urlService = urlService.replace('{product_id}', productId);
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService).pipe(map(response => {
            const components = [];
            const componentItems = response['data'];
            for (const component of componentItems) {
                const templateComponent = new TemplateComponent(component.template_component_id);
                templateComponent.name = component.template_component_name;
                templateComponent.displayLabel = component.display_label;
                templateComponent.productLine = ProductLine[ProductLine[component.product_line]];
                templateComponent.componentType = ComponentType[ComponentType[component.component_type]];
                templateComponent.deliveryPath = new TemplateDeliveryPath(component.delivery_path);
                templateComponent.contents = [];
                for (const tcci of component.contents) {
                    const templateComponentContent = new TemplateComponentContent(tcci.template_component_content_id);
                    templateComponentContent.templateComponent = templateComponent;
                    templateComponentContent.content = tcci.content;
                    templateComponentContent.locale = tcci.locale;
                    templateComponentContent.productId = tcci.product_id;
                    templateComponentContent.sampleContent = tcci.sample_content;
                    templateComponentContent.isDefaultInd = tcci.is_default_ind;
                    templateComponent.contents.push(templateComponentContent);
                }
                templateComponent.templates = [];
                for (const t of component.templates) {
                    const template = new Template(t.template_name);
                    template.displayLabel = t.display_label;
                    template.productLine = t.product_line;
                    templateComponent.templates.push(template);
                }
                components.push(templateComponent);
            }
            this.componentsStructureLastCallError = false;
            return components;
        }), catchError((err) => {
            const message = `In TemplatesService.findComponentsContents(${localeName},${productId}).`;
            this.componentsStructureLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function TemplatesService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemplatesService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplatesService, factory: TemplatesService.ɵfac, providedIn: 'root' }); }
}
