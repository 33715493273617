import { BulkEditChangeTypeEnum } from '../../model/bulk-edit-change-type.enum';
import * as i0 from "@angular/core";
export class RelevantBulkEditChangesFilterPipe {
    static filter(changeTypes, bes, includeEmpty = false) {
        return new RelevantBulkEditChangesFilterPipe().transform(changeTypes, bes, includeEmpty);
    }
    transform(changeTypes, bes, includeEmpty = false) {
        if (!changeTypes || !bes || bes.error || !bes.changes) {
            return changeTypes;
        }
        return changeTypes.filter(ct => ct !== BulkEditChangeTypeEnum.UNCHANGED &&
            (includeEmpty ||
                (!includeEmpty && bes.changes.get(ct).length > 0)));
    }
    static { this.ɵfac = function RelevantBulkEditChangesFilterPipe_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || RelevantBulkEditChangesFilterPipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "relevantBulkEditChangesFilter", type: RelevantBulkEditChangesFilterPipe, pure: true }); }
}
