import { EventEmitter } from '@angular/core';
import * as XLSX from 'xlsx';
import { factory } from '../../../shared/app-logging';
import { TemplatesService } from '../../../services/templates.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/operator-programs.service";
import * as i2 from "../../../services/locales.service";
import * as i3 from "../../../services/bulk-edit-excel.service";
import * as i4 from "ngx-toastr";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
function TemplateBulkEditExportComponent_option_11_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const locale_r1 = ctx.$implicit;
    i0.ɵɵproperty("ngValue", locale_r1);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", locale_r1.displayLabels.en_US, " ");
} }
function TemplateBulkEditExportComponent_option_16_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const product_r2 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngValue", product_r2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("", ctx_r2.getOperatorProgramProductDisplayLabel(product_r2), " ");
} }
function TemplateBulkEditExportComponent_div_17_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵelement(1, "br");
    i0.ɵɵelementStart(2, "button", 13);
    i0.ɵɵlistener("click", function TemplateBulkEditExportComponent_div_17_Template_button_click_2_listener() { i0.ɵɵrestoreView(_r4); const ctx_r2 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r2.downloadBulkEditExcelVBAFile()); });
    i0.ɵɵelement(3, "i");
    i0.ɵɵelementEnd()();
} if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("disabled", ctx_r2.exportingExcelFile);
    i0.ɵɵadvance();
    i0.ɵɵclassMap("fa fa-lg " + (ctx_r2.exportingExcelFile ? "fa-spinner fa-spin" : "fa-download"));
} }
const logger = factory.getLogger('TemplateBulkEditExportComponent');
export class TemplateBulkEditExportComponent {
    constructor(operatorProgramsService, localesService, excelService, toastr) {
        this.operatorProgramsService = operatorProgramsService;
        this.localesService = localesService;
        this.excelService = excelService;
        this.toastr = toastr;
        this.productSelChange = new EventEmitter();
        this.localeSelChange = new EventEmitter();
        this.getOperatorProgramProductDisplayLabel = TemplatesService.getOperatorProgramProductDisplayLabel;
    }
    ngOnInit() {
    }
    downloadBulkEditExcelVBAFile() {
        if (!this.localeSel) {
            return;
        }
        this.exportingExcelFile = true;
        this.excelService.exportExcelVBAFile(this.localeSel, this.productSel).subscribe(wb => {
            this.exportingExcelFile = false;
            const filename = 'Template-Bulk-Edit[' + this.localeSel.localeName + '][' +
                this.getOperatorProgramProductDisplayLabel(this.productSel) +
                '](' + (new Date()).toISOString() + ').xlsm';
            XLSX.writeFile(wb, filename, { bookType: 'xlsm' });
            this.toastr.success(`The data was successfully exported into an Excel® file.`, `Success`);
        }, error => {
            this.exportingExcelFile = false;
            error.message = `While exporting excel file. ${error.message}`;
            throw error;
        });
    }
    checkSelection() {
        return this.products && this.products.length > 0 && this.localeSel && true;
    }
    setLocaleSel(target) {
        this.localeSel = target;
        this.localeSelChange.emit(this.localeSel);
    }
    setProgramSel(target) {
        this.productSel = target;
        this.productSelChange.emit(this.productSel);
    }
    static { this.ɵfac = function TemplateBulkEditExportComponent_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemplateBulkEditExportComponent)(i0.ɵɵdirectiveInject(i1.OperatorProgramsService), i0.ɵɵdirectiveInject(i2.LocalesService), i0.ɵɵdirectiveInject(i3.BulkEditExcelService), i0.ɵɵdirectiveInject(i4.ToastrService)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: TemplateBulkEditExportComponent, selectors: [["app-template-bulk-edit-export"]], inputs: { locales: "locales", localeSel: "localeSel", products: "products", productSel: "productSel" }, outputs: { productSelChange: "productSelChange", localeSelChange: "localeSelChange" }, decls: 18, vars: 5, consts: [[1, "container-fluid"], [1, "row", "m-4"], [1, "form-group", "col-md-1"], [1, "control-label", "fw-bold"], [1, "form-group", "col-md-4"], ["for", "localeSelection", 1, "control-label", "fw-bold"], ["id", "localeSelection", "name", "localeSelection", 1, "form-control", 3, "ngModelChange", "ngModel"], [3, "ngValue", 4, "ngFor", "ngForOf"], ["for", "programSelection", 1, "control-label", "fw-bold"], ["id", "programSelection", "name", "programSelection", 1, "form-control", 3, "ngModelChange", "ngModel"], ["class", "col", "style", "float: right", 4, "ngIf"], [3, "ngValue"], [1, "col", 2, "float", "right"], ["id", "downloadBulkEditExcelVBAFile", "type", "button", "title", "Download", 1, "btn", "btn-primary", 2, "float", "right", "margin-top", "6px", 3, "click", "disabled"]], template: function TemplateBulkEditExportComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "section")(1, "div", 0)(2, "form")(3, "div", 1)(4, "div", 2)(5, "label", 3);
            i0.ɵɵtext(6, "Please\u00A0select:");
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(7, "div", 4)(8, "label", 5);
            i0.ɵɵtext(9, "Locale:");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(10, "select", 6);
            i0.ɵɵlistener("ngModelChange", function TemplateBulkEditExportComponent_Template_select_ngModelChange_10_listener($event) { return ctx.setLocaleSel($event); });
            i0.ɵɵtemplate(11, TemplateBulkEditExportComponent_option_11_Template, 2, 2, "option", 7);
            i0.ɵɵelementEnd()();
            i0.ɵɵelementStart(12, "div", 4)(13, "label", 8);
            i0.ɵɵtext(14, "Operator - Program - Product:");
            i0.ɵɵelementEnd();
            i0.ɵɵelementStart(15, "select", 9);
            i0.ɵɵlistener("ngModelChange", function TemplateBulkEditExportComponent_Template_select_ngModelChange_15_listener($event) { return ctx.setProgramSel($event); });
            i0.ɵɵtemplate(16, TemplateBulkEditExportComponent_option_16_Template, 2, 2, "option", 7);
            i0.ɵɵelementEnd()();
            i0.ɵɵtemplate(17, TemplateBulkEditExportComponent_div_17_Template, 4, 3, "div", 10);
            i0.ɵɵelementEnd()()()();
        } if (rf & 2) {
            i0.ɵɵadvance(10);
            i0.ɵɵproperty("ngModel", ctx.localeSel);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngForOf", ctx.locales);
            i0.ɵɵadvance(4);
            i0.ɵɵproperty("ngModel", ctx.productSel);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngForOf", ctx.products);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", ctx.checkSelection());
        } }, dependencies: [i5.NgForOf, i5.NgIf, i6.ɵNgNoValidate, i6.NgSelectOption, i6.ɵNgSelectMultipleOption, i6.SelectControlValueAccessor, i6.NgControlStatus, i6.NgControlStatusGroup, i6.NgModel, i6.NgForm], styles: [".modal.fade.show[_ngcontent-%COMP%] {\n  opacity: 1;\n}"] }); }
}
