import { EMPTY } from 'rxjs';
import { AppConfig } from '../app-config';
import { factory } from '../app-logging';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { RedirectService } from '../../services/redirect.service';
import * as i0 from "@angular/core";
const logger = factory.getLogger('TokenInterceptor');
export class TokenInterceptor {
    constructor(injector) {
        this.injector = injector;
        this.redirector = this.injector.get(RedirectService);
        this.cookieService = this.injector.get(CookieService);
        this.authService = this.injector.get(AuthService);
    }
    intercept(request, next) {
        logger.trace('Intercepted: ' + JSON.stringify(request));
        if (!(request.url && request.url.startsWith(environment.contentLibraryApiBaseUrl))) {
            // The site doesn't require cookie token
            return next.handle(request);
        }
        if (this.cookieService.check(AppConfig.sessionTokenVariableName)) {
            if (!this.authService.isLoggedIn()) {
                this.authService.takeLoginDetailsFromCookie();
            }
            let req = request.clone({
                withCredentials: true
            });
            if (!req.headers.has('Content-Type')) {
                req = req.clone({ headers: req.headers.append('Content-Type', 'application/json') });
            }
            if (!req.headers.has('Accept')) {
                req = req.clone({ headers: req.headers.append('Accept', 'application/json') });
            }
            // Continue Execution with modified request
            return next.handle(req);
        }
        else {
            this.authService.logout();
            this.redirector.goToLoginPage();
            // Cancel request
            return EMPTY;
        }
        // Continue Request Execution
        // return next.handle(request);
    }
    static { this.ɵfac = function TokenInterceptor_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TokenInterceptor)(i0.ɵɵinject(i0.Injector)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TokenInterceptor, factory: TokenInterceptor.ɵfac }); }
}
