import { Operator } from './operator';
export class Program {
    constructor(id, operator, programName, displayLabel, shortDisplayLabel, status) {
        this.id = id;
        this.operator = operator;
        this.programName = programName;
        this.displayLabel = displayLabel;
        this.shortDisplayLabel = shortDisplayLabel;
        this.status = status;
    }
    static newInstance(id, operatorId, programName, displayLabel, shortDisplayLabel, status) {
        return new Program(id, new Operator(operatorId), programName, displayLabel, shortDisplayLabel, status);
    }
    clone() {
        return new Program(this.id, this.operator, this.programName, this.displayLabel, this.shortDisplayLabel, this.status);
    }
}
