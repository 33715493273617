export const environment = {
    production: false,
    contentLibraryUiRootUrl: 'https://contentlibrary.dev-apps.enelx.net',
    contentLibraryApiBaseUrl: 'https://contentlibrary-api.dev-apps.enelx.net',
    ngpServiceCatalogReferenceApiBaseUrl: 'https://reference.ngp.dev-svcs.enernoc.net',
    ngpServiceCatalogReferenceApiKey: 'gGjoFe1Hop2TROufMHROk2f9PWyGpWnI7OLwMWTK',
    infoPushApiBaseUrl: 'https://info-push-api.dev-svcs.enelx.net',
    openAmUiRootUrl: 'https://openam.dev-apps.enelx.net/openam/XUI/',
    openAmUiLoginUrl: 'https://openam.dev-apps.enelx.net/openam/XUI/?realm=enelx&goto=https://contentlibrary.dev-apps.enelx.net/&realm=enelx#login/',
    openAmUiLogoutUrl: 'https://openam.dev-apps.enelx.net/openam/XUI/?realm=enelx#loggedOut/',
    openAmCookieDomain: '.enelx.net',
    userNavApi: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',
    brands: {
        enelx: {
            baseUrl: '/',
        },
    },
    defaults: {
        brand: 'enelx',
        locale: 'en-us',
    }
};
