import { factory } from '../shared/app-logging';
import { AuthService } from '../services/auth.service';
import * as i0 from "@angular/core";
const logger = factory.getLogger('RedirectGuard');
export class RedirectGuard {
    constructor(injector) {
        this.injector = injector;
    }
    canActivate(next, state) {
        const targetURL = next.data ? next.data.externalUrl : null;
        if (!targetURL) {
            return false;
        }
        if (next.data && next.data.logout) {
            this.injector.get(AuthService).logout();
        }
        logger.info('Redirecting: ' + targetURL);
        window.open(targetURL, '_self');
        return true;
    }
    static { this.ɵfac = function RedirectGuard_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || RedirectGuard)(i0.ɵɵinject(i0.Injector)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RedirectGuard, factory: RedirectGuard.ɵfac, providedIn: 'root' }); }
}
