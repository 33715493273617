import { environment } from "@/environments/environment";
import { HttpHeaders } from "@angular/common/http";
import { signal } from "@angular/core";
import { map, catchError, throwError, BehaviorSubject } from "rxjs";
import { AppConfig } from "../shared/app-config";
import { ErrorDataService } from "./error-data.service";
import { EventTypeModel, TemplateModel, TemplatePriorityModel } from "../model/template.priority.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TemplatesPriorityService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // Dispatch if there is a loading in progress
        this.isLoadingActive = new BehaviorSubject(false);
        // Dispatch if save button is enabled
        this.isSaveEnabled = new BehaviorSubject(false);
        // Dispatch what actions has been done (EDIT, CANCEL, CLEAR_ALL, SAVE, SAVE_OK)
        this.action = new BehaviorSubject('');
        // Dispatch error messages
        this.error = new BehaviorSubject(null);
        // Dispatch the OPPs list
        this.opps = new BehaviorSubject([]);
        // Dispatch the selected entries in the dropdown
        this.dropdownSelectedEntries = new BehaviorSubject([]);
        // Dispatch the default template priorities
        this.defaultTemplatePriorities = new BehaviorSubject([]);
        // Dispatch the template priorities (freezed state)
        this.templatePriorities = new BehaviorSubject([]);
        // Dispatch the edited template priorities (that must be saved)
        this.editedTemplatePriorities = new BehaviorSubject([]);
        //Data to log in splunk
        this.loggedTemplatePriorities = signal([]);
    }
    setIsLoadingActive(value) {
        this.isLoadingActive.next(value);
    }
    getIsLoadingActive() {
        return this.isLoadingActive;
    }
    setIsSaveEnabled(value) {
        this.isSaveEnabled.next(value);
    }
    getIsSaveEnabled() {
        return this.isSaveEnabled;
    }
    setAction(value) {
        this.action.next(value);
    }
    getAction() {
        return this.action;
    }
    setError(value) {
        this.error.next(value);
    }
    getError() {
        return this.error;
    }
    setOpps(value) {
        this.opps.next(value);
    }
    getOpps() {
        return this.opps;
    }
    setDropdownSelectedEntries(value) {
        this.dropdownSelectedEntries.next(value);
    }
    getDropdownSelectedEntries() {
        return this.dropdownSelectedEntries;
    }
    setDefaultTemplatePriorities(value) {
        this.defaultTemplatePriorities.next(value);
    }
    getDefaultTemplatePriorities() {
        return this.defaultTemplatePriorities;
    }
    setTemplatePriorities(value) {
        this.templatePriorities.next(value);
    }
    getTemplatePriorities() {
        return this.templatePriorities;
    }
    setEditedTemplatePriorities(value) {
        this.editedTemplatePriorities.next(value);
    }
    getEditedTemplatePriorities() {
        return this.editedTemplatePriorities;
    }
    getTemplatesWithByEventTypesEnabled() {
        return ['CURTAIL', 'FOLLOW_UP_CONFIRM', 'RESTORE'];
    }
    // HTTP - GET Template Priorities
    retrieveTemplatePriorities(eventTypes, templates, product_id) {
        const body = {
            event_types: eventTypes,
            template_names: templates.map((template) => template.name),
            product_id: product_id == 'ALL' ? null : product_id
        };
        let headers = new HttpHeaders();
        headers = headers.append("Content-Type", "application/json");
        headers = headers.append("Accept", "application/json");
        return this.httpClient
            //CHANGE URL WHEN API IS READY
            .post(environment.contentLibraryApiBaseUrl + AppConfig.urlPriorities, body, {
            headers: headers,
        })
            .pipe(map((response) => {
            const templatePriorities = [];
            response["data"].forEach((res) => {
                let templatePriority = new TemplatePriorityModel(res.template_name, templates.find((template) => template.name === res.template_name).label, res.event_types, res.priority, [], []);
                templatePriorities.push(templatePriority);
            });
            return templatePriorities.sort((a, b) => {
                const nameA = a.template_name;
                const nameB = b.template_name;
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        }), catchError((err) => {
            const message = `No Template Priority Found.`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    // HTTP - POST Save Template Priority
    saveTemplatePriority(template_name, event_type, priority, product_id) {
        if (priority) {
            priority = priority.toUpperCase();
        }
        return this.httpClient.post(environment.contentLibraryApiBaseUrl + AppConfig.urlPriority, { template_name, event_type, priority, product_id }).pipe(map(response => {
            if (response['code'] === 200) {
                return response['message'];
            }
        }), catchError((err) => {
            const message = `Error in TemplatePriorityService saveTemplatePriority`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    // HTTP - POST Save Template Priorities (Bulk API)
    saveTemplatePriorities(templatepriorities, logged_info) {
        templatepriorities.forEach((templatepriority) => {
            if (templatepriority.priority) {
                templatepriority.priority = templatepriority.priority.toUpperCase();
            }
        });
        const data = {
            templatepriorities: templatepriorities,
            logged_info: logged_info,
        };
        return this.httpClient.post(environment.contentLibraryApiBaseUrl + AppConfig.urlPrioritiesBulk, data).pipe(map(response => {
            if (response['code'] === 200) {
                return response['message'];
            }
        }), catchError((err) => {
            const message = `Error in TemplatePriorityService saveTemplatePriorities`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    // HTTP - GET Event Types
    retrieveEventTypes() {
        return this.httpClient
            //CHANGE URL WHEN API IS READY
            .get(environment.contentLibraryApiBaseUrl + AppConfig.urlEvents)
            .pipe(map((response) => {
            const eventTypes = [];
            response['data'].forEach((res) => {
                eventTypes.push(new EventTypeModel(res.code, res.label));
            });
            return eventTypes;
        }), catchError((err) => {
            const message = `No Event Types Found.`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    // HTTP - GET Template Names
    retrieveTemplatesNames() {
        return this.httpClient
            //CHANGE URL WHEN API IS READY
            .get(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplates)
            .pipe(map((response) => {
            const templates = [];
            response['data'].forEach((res) => {
                templates.push(new TemplateModel(res.template_name, res.display_label));
            });
            return templates;
        }), catchError((err) => {
            const message = `No Templates Found.`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    static { this.ɵfac = function TemplatesPriorityService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemplatesPriorityService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplatesPriorityService, factory: TemplatesPriorityService.ɵfac, providedIn: "root" }); }
}
