import { NgxTextDiffComponent } from './ngx-text-diff.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoaderSpinnerComponent } from './loader-spinner/loader-spinner.component';
import { FormatLinePipe } from './format-line.pipe';
import { ContainerDirective } from './ngx-text-diff-container.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import * as i0 from "@angular/core";
export class NgxTextDiffModule {
    static { this.ɵfac = function NgxTextDiffModule_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || NgxTextDiffModule)(); }; }
    static { this.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: NgxTextDiffModule }); }
    static { this.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule, FormsModule, ScrollingModule] }); }
}
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(NgxTextDiffModule, { declarations: [NgxTextDiffComponent, LoaderSpinnerComponent, FormatLinePipe, ContainerDirective], imports: [CommonModule, FormsModule, ScrollingModule], exports: [NgxTextDiffComponent] }); })();
