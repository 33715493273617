import * as i0 from "@angular/core";
export class FormatLinePipe {
    transform(line, diffs) {
        if (!line) {
            return ' ';
        }
        if (!!diffs && diffs.length > 0) {
            /*diffs.forEach(diff => {
              line = line.replace(diff, `<span class="highli">${diff}</span>`);
            });*/
        }
        return line
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/ /g, '&nbsp;');
    }
    static { this.ɵfac = function FormatLinePipe_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || FormatLinePipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "formatLine", type: FormatLinePipe, pure: true }); }
}
