import { FateHtmlParserService, FateNode } from 'fate-editor';
import * as i0 from "@angular/core";
export class FateCustomHtmlParserService extends FateHtmlParserService {
    constructor() {
        super();
    }
    parseType(element) {
        if (element.nodeName === 'BR') {
            return [new FateNode('br')];
        }
        else {
            return super.parseType(element);
        }
    }
    serializeType(node) {
        if (node.type === 'br') {
            return '<br/>';
        }
        else {
            return super.serializeType(node);
        }
    }
    static { this.ɵfac = function FateCustomHtmlParserService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || FateCustomHtmlParserService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FateCustomHtmlParserService, factory: FateCustomHtmlParserService.ɵfac, providedIn: 'root' }); }
}
