import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { Program } from '../model/program';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('OperatorProgramsService');
export class OperatorProgramsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.programsLastCallError = false;
    }
    findPrograms() {
        if (this.programs && !this.programsLastCallError) {
            logger.debug('findPrograms: Cache hit.');
        }
        this.programs = (this.programs && !this.programsLastCallError) ? this.programs : this.fetchPrograms();
        return this.programs;
    }
    fetchPrograms() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlPrograms).pipe(map(response => {
            const programs = [];
            for (const program of response['data']) {
                if (program.hasOwnProperty('id')) {
                    if (program.hasOwnProperty('display_label')) {
                        let programName, displayLabel, shortDisplayLabel;
                        try {
                            programName = JSON.parse(program.program_name).en_US;
                        }
                        catch (parseException) {
                            logger.warn(`The program name was expected to be a JSON, ` +
                                `taking the raw string as program name:  ${program.program_name}`, parseException);
                            programName = program.program_name;
                        }
                        displayLabel = program.display_label || '';
                        shortDisplayLabel = program.short_display_label || program.display_label || '';
                        programs.push(Program.newInstance(program.id, program.operator_id, programName, displayLabel, shortDisplayLabel, program.status));
                    }
                    else {
                        logger.error(`Program display label is missing. Ignoring program item from response ${program.id}`);
                    }
                }
                else {
                    logger.error(`Program id is missing. Ignoring program item from response ${program}`);
                }
            }
            logger.debug(`findPrograms: ${programs.length} programs fetched from REST`);
            this.programsLastCallError = false;
            return programs.sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
        }), catchError((err) => {
            const message = `In OperatorProgramsService.findPrograms().`;
            this.programsLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    getProgramsWithOperator(programs, operators) {
        const result = [];
        for (const program of programs) {
            const operator = operators.find(op => op.id === program.operator.id);
            if (operator) {
                program.operator = operator;
                result.push(program);
            }
        }
        return result;
    }
    findProgramsWithOperator(operators) {
        return new Observable(subscriber => {
            let result;
            this.findPrograms().subscribe(programs => {
                result = this.getProgramsWithOperator(programs, operators);
                subscriber.next(result);
                subscriber.complete();
            });
        });
    }
    static { this.ɵfac = function OperatorProgramsService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || OperatorProgramsService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OperatorProgramsService, factory: OperatorProgramsService.ɵfac, providedIn: 'root' }); }
}
