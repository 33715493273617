import { factory } from '../shared/app-logging';
import { TtsApplicationDefaults } from '../model/ttsApplicationDefaults';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { ErrorDataService } from './error-data.service';
import { AppConfig } from '../shared/app-config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('TtsConfigService');
export class TtsConfigService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.ttsApplicationDefaults = {};
        this.ttsApplicationDefaultsLastCallError = {};
    }
    getTtsApplicationDefaults(xcoreLocale, sourceApp) {
        if (this.ttsApplicationDefaults[sourceApp] === undefined) {
            this.ttsApplicationDefaults[sourceApp] = {};
            this.ttsApplicationDefaultsLastCallError[sourceApp] = {};
        }
        if (this.ttsApplicationDefaults[sourceApp][xcoreLocale] && !this.ttsApplicationDefaultsLastCallError[sourceApp][xcoreLocale]) {
            logger.debug('getTtsApplicationDefaults: Cache hit.');
        }
        this.ttsApplicationDefaults[sourceApp][xcoreLocale] =
            (this.ttsApplicationDefaults[sourceApp][xcoreLocale] && !this.ttsApplicationDefaultsLastCallError[sourceApp][xcoreLocale]) ?
                this.ttsApplicationDefaults[sourceApp][xcoreLocale] :
                this.fetchTtsApplicationDefaults(xcoreLocale, sourceApp);
        return this.ttsApplicationDefaults[sourceApp][xcoreLocale];
    }
    fetchTtsApplicationDefaults(xcoreLocale, sourceApp) {
        let urlService = AppConfig.urlTtsApplicationDefaultsByXcoreLocaleAndApplication;
        urlService = urlService.replace('{xcore_locale}', xcoreLocale);
        urlService = urlService.replace('{tts_application_name}', sourceApp);
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + urlService)
            .pipe(map((response) => {
            if (response['data']) {
                logger.debug('getTtsApplicationDefaults: Data fetched from REST');
                this.ttsApplicationDefaultsLastCallError[sourceApp][xcoreLocale] = false;
                return TtsApplicationDefaults.parse(response['data']);
            }
        }), catchError((err) => {
            const message = `In TtsConfigService.getTtsApplicationDefaults().`;
            this.ttsApplicationDefaultsLastCallError[sourceApp][xcoreLocale] = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function TtsConfigService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TtsConfigService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TtsConfigService, factory: TtsConfigService.ɵfac, providedIn: 'root' }); }
}
