import { Router } from '@angular/router';
import { factory } from '../shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "./error-data.service";
const logger = factory.getLogger('RedirectService');
export class RedirectService {
    constructor(injector, errorDataService) {
        this.injector = injector;
        this.errorDataService = errorDataService;
        this.router = this.injector.get(Router);
    }
    goToErrorPage(err) {
        this.errorDataService.setError(err);
        this.router.navigateByUrl('error').then(value => {
            logger.debug(`Navigation to ERROR PAGE Fulfilled: ${value}`);
            if (value === false) { // When not TRUE and not NULL
                throw new Error(`An exception occurred while attempting to navigate to ERROR page.`);
            }
        }, reason => {
            throw new Error(`Navigation to ERROR PAGE Rejected: ${reason}`);
        });
    }
    goToLoginPage() {
        this.router.navigateByUrl('login').then(value => {
            logger.debug(`Navigation to LOGIN PAGE Fulfilled: ${value}`);
            if (value === false) { // When not TRUE and not NULL
                throw new Error(`An exception occurred while attempting to navigate to LOGIN page.`);
            }
        }, reason => {
            throw new Error(`Navigation to LOGIN PAGE Rejected: ${reason}`);
        });
    }
    goToLogoutPage() {
        this.router.navigateByUrl('logout').then(value => {
            logger.debug(`Navigation to LOGOUT PAGE Fulfilled: ${value}`);
            if (value === false) { // When not TRUE and not NULL
                throw new Error(`An exception occurred while attempting to navigate to LOGOUT page.`);
            }
        }, reason => {
            throw new Error(`Navigation to LOGOUT PAGE Rejected: ${reason}`);
        });
    }
    static { this.ɵfac = function RedirectService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || RedirectService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.ErrorDataService)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: RedirectService, factory: RedirectService.ɵfac, providedIn: 'root' }); }
}
