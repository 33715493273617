import { CookieService } from 'ngx-cookie-service';
import { of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
const logger = factory.getLogger('AuthService');
export class AuthService {
    constructor(injector) {
        this.injector = injector;
        this.cookieService = this.injector.get(CookieService);
        this.httpClient = this.injector.get(HttpClient);
    }
    checkAuthorization() {
        if (this.isAuth) {
            return of(this.isAuth);
        }
        const _self = this;
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlPing).pipe(map((response) => {
            logger.debug(`Got response: ${JSON.stringify(response)}`);
            if (response && response.status >= 200 && response.status < 300) {
                _self.isAuth = true;
                return true;
            }
            return false;
        }), catchError((err) => {
            const message = `In AuthService.checkAuthorization().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    logIn(enoc_session, default_brand, locale, user_name) {
        this.enoc_session = enoc_session;
        this.default_brand = default_brand;
        this.locale = locale;
        this.user_name = user_name;
    }
    logout() {
        this.enoc_session = null;
        this.default_brand = null;
        this.locale = null;
        this.user_name = null;
        this.cookieService.deleteAll('/', environment.openAmCookieDomain);
    }
    isLoggedIn() {
        return this.enoc_session && this.enoc_session.length > 0;
    }
    getToken() {
        return this.enoc_session;
    }
    getUserName() {
        return this.user_name;
    }
    takeLoginDetailsFromCookie() {
        if (this.cookieService.check(AppConfig.sessionTokenVariableName)) {
            const enoc_session = this.cookieService.get(AppConfig.sessionTokenVariableName);
            const user_name = this.cookieService.get(AppConfig.sessionUsernameVariableName);
            const locale = this.cookieService.get('locale');
            const default_brand = this.cookieService.get('default_brand');
            this.logIn(enoc_session, default_brand, locale, user_name);
        }
    }
    static { this.ɵfac = function AuthService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(i0.Injector)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AuthService, factory: AuthService.ɵfac, providedIn: 'root' }); }
}
