import { EventEmitter } from '@angular/core';
import { TtsOutputFormatType } from '../../../model/tts.enum';
import { factory } from '../../../shared/app-logging';
import * as i0 from "@angular/core";
import * as i1 from "../../../services/tts.service";
import * as i2 from "ngx-filesaver";
import * as i3 from "@angular/common";
import * as i4 from "ngx-bootstrap/tooltip";
import * as i5 from "../../../plyr/plyr/plyr.component";
const _c0 = a0 => ({ controls: a0 });
function ContentPlayerComponent_a_1_Template(rf, ctx) { if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 4);
    i0.ɵɵlistener("click", function ContentPlayerComponent_a_1_Template_a_click_0_listener($event) { i0.ɵɵrestoreView(_r1); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.load($event)); });
    i0.ɵɵelementEnd();
} }
function ContentPlayerComponent_span_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "span", 5);
} }
function ContentPlayerComponent_plyr_3_Template(rf, ctx) { if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "plyr", 6);
    i0.ɵɵlistener("plyrInit", function ContentPlayerComponent_plyr_3_Template_plyr_plyrInit_0_listener($event) { i0.ɵɵrestoreView(_r3); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.contentPlyr = $event); });
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("plyrOptions", i0.ɵɵpureFunction1(2, _c0, ctx_r1.getControlsType()))("plyrSources", ctx_r1.plyrSources);
} }
function ContentPlayerComponent_a_4_Template(rf, ctx) { if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 7);
    i0.ɵɵlistener("click", function ContentPlayerComponent_a_4_Template_a_click_0_listener() { i0.ɵɵrestoreView(_r4); const ctx_r1 = i0.ɵɵnextContext(); return i0.ɵɵresetView(ctx_r1.downloadAudioFile(ctx_r1.outputFormatTypeSel)); });
    i0.ɵɵelementEnd();
} }
const logger = factory.getLogger('ContentPlayerComponent');
export class ContentPlayerOptions {
    constructor(filename, addSuffixDatetime) {
        this.filename = filename;
        this.addSuffixDatetime = addSuffixDatetime;
    }
}
export class ContentPlayerComponent {
    constructor(ttsService, fileSaverService, datePipe) {
        this.ttsService = ttsService;
        this.fileSaverService = fileSaverService;
        this.datePipe = datePipe;
        // tslint:disable-next-line:no-output-rename
        this.loadEmitter = new EventEmitter();
        this.outputFormatTypeSel = TtsOutputFormatType.MP3;
        this.plyrControlsPlPgMt = `
  <div class="plyr__controls" style="line-height: 1em; margin-top: -5px">
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <a class="icon--pressed fa fa-pause" role="presentation"><use xlink:href="#plyr-pause"></use></a>
        <a class="icon--not-pressed fa fa-play" role="presentation"><use xlink:href="#plyr-play"></use></a>
        <!--<span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Pause</span>-->
        <!--<span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Play</span>-->
    </button>
    <div class="plyr__progress">
        <input data-plyr="seek" type="range" min="0" max="100" step="0.01" value="0" aria-label="Seek">
        <progress class="plyr__progress__buffer" min="0" max="100" value="0">% buffered</progress>
        <span role="tooltip" class="plyr__tooltip">00:00</span>
    </div>
    <div class="plyr__time plyr__time--current" aria-label="Current time">00:00</div>
    <div class="plyr__time plyr__time--duration" aria-label="Duration">00:00</div>
    <button type="button" class="plyr__control" aria-label="Mute" data-plyr="mute">
        <a class="icon--pressed fa fa-volume-mute fa-lg" role="presentation"><use xlink:href="#plyr-muted"></use></a>
        <a class="icon--not-pressed fa fa-volume-up fa-lg" role="presentation"><use xlink:href="#plyr-volume"></use></a>
        <!--<span class="label&#45;&#45;pressed plyr__tooltip" role="tooltip">Unmute</span>-->
        <!--<span class="label&#45;&#45;not-pressed plyr__tooltip" role="tooltip">Mute</span>-->
    </button>
  </div>
  `;
        this.plyrControlsPl = `
  <div class="plyr__controls" style="line-height: 1em;">
    <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
        <a class="icon--pressed fa fa-pause" role="presentation"><use xlink:href="#plyr-pause"></use></a>
        <a class="icon--not-pressed fa fa-play" role="presentation"><use xlink:href="#plyr-play"></use></a>
        <!--<span class="label--pressed plyr__tooltip" role="tooltip">Pause</span>-->
        <!--<span class="label--not-pressed plyr__tooltip" role="tooltip">Play</span>-->
    </button>
  </div>
  `;
    }
    ngOnInit() {
        this.reset();
    }
    reset() {
        logger.debug('reset');
        this.contentPlyr = null;
        this.plyrSources = [];
        this.audioLoaded = false;
        this.audioLoading = false;
        this.audioDownloading = false;
    }
    getControlsType() {
        switch (this.controlsType) {
            case 'Pl':
                return this.plyrControlsPl;
            case 'PlPgMt':
            default:
                return this.plyrControlsPlPgMt;
        }
    }
    load(e) {
        logger.debug(`Load event ${e}`);
        if (this.audioText && this.audioLocale && this.audioText.length > 0 && this.audioLocale.length > 0) {
            logger.debug(`Load event calling directly to TTS Service`);
            this.loadTts(this.audioText, this.audioLocale, this.audioVoice);
        }
        else {
            logger.debug(`Load event emitting custom event to be captured to call TTS Service`);
            this.loadEmitter.emit(new CustomEvent('load', { detail: { contentId: this.elContentId } }));
        }
    }
    loadTts(text, locale, voice, options) {
        this.audioLoading = true;
        this.audioOptions = options;
        this.ttsService.getTts(text, TtsOutputFormatType.MP3, locale, voice).subscribe((data) => {
            logger.debug(`Audio binary content received`);
            this.audioLoading = false;
            this.plyrSources = [
                {
                    src: URL.createObjectURL(data),
                    type: 'audio/mp3'
                }
            ];
            this.audioLoaded = true;
            this.audioBlob = data;
        }, error => {
            logger.debug(`Audio load error`);
            this.audioLoaded = false;
            this.audioLoading = false;
            error.message = `While performing Text To Speech conversion. ${error.message}`;
            throw error;
        });
    }
    downloadAudioFile(outputFormatType = TtsOutputFormatType.MP3) {
        const filename = this.formatAudioFilename(outputFormatType);
        if (outputFormatType !== TtsOutputFormatType.MP3) {
            logger.debug(`Download audio file (${filename}, request different format: ${outputFormatType}`);
            this.audioDownloading = true;
            this.ttsService.getTts(this.audioText, TtsOutputFormatType.MP3, this.audioLocale, this.audioVoice)
                .subscribe((data) => {
                logger.debug(`Audio download request successfully performed... saving file.`);
                this.audioDownloading = false;
                this.fileSaverService.save(data, filename);
            }, error => {
                logger.debug(`Audio download error`);
                this.audioDownloading = false;
                error.message = `While performing Text To Speech conversion. ${error.message}`;
                throw error;
            });
        }
        else {
            logger.debug(`Download MP3 audio file (${filename}`);
            this.fileSaverService.save(this.audioBlob, filename);
        }
    }
    formatAudioFilename(outputFormatType = TtsOutputFormatType.MP3) {
        let filename = 'AudioFile';
        if (this.audioOptions) {
            if (this.audioOptions.filename) {
                filename += this.audioOptions.filename;
            }
            if (this.audioOptions.addSuffixDatetime) {
                filename += '[' + this.datePipe.transform(Date.now(), 'yyyyMMddHHmm') + ']';
            }
        }
        filename += '.' + outputFormatType;
        return filename;
    }
    static { this.ɵfac = function ContentPlayerComponent_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ContentPlayerComponent)(i0.ɵɵdirectiveInject(i1.TtsService), i0.ɵɵdirectiveInject(i2.FileSaverService), i0.ɵɵdirectiveInject(i3.DatePipe)); }; }
    static { this.ɵcmp = /*@__PURE__*/ i0.ɵɵdefineComponent({ type: ContentPlayerComponent, selectors: [["app-content-player"]], inputs: { elContentId: [0, "for", "elContentId"], audioText: [0, "text", "audioText"], audioLocale: [0, "locale", "audioLocale"], audioVoice: [0, "voice", "audioVoice"], controlsType: [0, "controls", "controlsType"], canDownload: [0, "download", "canDownload"] }, outputs: { loadEmitter: "load" }, decls: 5, vars: 4, consts: [["class", "fa fa-play-circle fa-lg", "tooltip", "Load audio", "container", "body", 3, "click", 4, "ngIf"], ["class", "fa fa-spinner fa-spin fa-lg", 4, "ngIf"], ["plyrType", "audio", 3, "plyrOptions", "plyrSources", "plyrInit", 4, "ngIf"], ["class", "fa fa-download fa-lg", "tooltip", "Download audio", "container", "body", 3, "click", 4, "ngIf"], ["tooltip", "Load audio", "container", "body", 1, "fa", "fa-play-circle", "fa-lg", 3, "click"], [1, "fa", "fa-spinner", "fa-spin", "fa-lg"], ["plyrType", "audio", 3, "plyrInit", "plyrOptions", "plyrSources"], ["tooltip", "Download audio", "container", "body", 1, "fa", "fa-download", "fa-lg", 3, "click"]], template: function ContentPlayerComponent_Template(rf, ctx) { if (rf & 1) {
            i0.ɵɵelementStart(0, "div");
            i0.ɵɵtemplate(1, ContentPlayerComponent_a_1_Template, 1, 0, "a", 0)(2, ContentPlayerComponent_span_2_Template, 1, 0, "span", 1);
            i0.ɵɵelementEnd();
            i0.ɵɵtemplate(3, ContentPlayerComponent_plyr_3_Template, 1, 4, "plyr", 2)(4, ContentPlayerComponent_a_4_Template, 1, 0, "a", 3);
        } if (rf & 2) {
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", !ctx.audioLoaded && !ctx.audioLoading);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", !ctx.audioLoaded && ctx.audioLoading);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", ctx.audioLoaded);
            i0.ɵɵadvance();
            i0.ɵɵproperty("ngIf", ctx.canDownload && ctx.audioLoaded && !ctx.audioDownloading);
        } }, dependencies: [i3.NgIf, i4.TooltipDirective, i5.PlyrComponent], styles: ["//[_ngcontent-%COMP%]   extracted[_ngcontent-%COMP%]   by[_ngcontent-%COMP%]   mini-css-extract-plugin\nexport[_ngcontent-%COMP%] {};"] }); }
}
