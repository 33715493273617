import { FateNode, FateParserService, FateType } from 'fate-editor';
import * as i0 from "@angular/core";
export class FateCustomParserService extends FateParserService {
    constructor() {
        super();
    }
    parseType(element) {
        if (element.nodeName === 'BR') {
            return [new FateNode('br')];
        }
        else {
            return super.parseType(element);
        }
    }
    serializeType(node) {
        if (node.type === FateType.LINK) {
            const value = !node.value.startsWith('http://') && !node.value.startsWith('https://') ? 'http://' + node.value : node.value;
            return '<a  href="' + value + '" target="_blank">' + this.serialize(node) + '</a>';
        }
        else if (node.type === 'br') {
            return '<br/>';
        }
        else {
            return super.serializeType(node);
        }
    }
    static { this.ɵfac = function FateCustomParserService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || FateCustomParserService)(); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FateCustomParserService, factory: FateCustomParserService.ɵfac, providedIn: 'root' }); }
}
