import * as i0 from "@angular/core";
export class ComponentTypeFilterPipe {
    transform(definitions, filter) {
        if (!definitions || !filter) {
            return definitions;
        }
        return definitions.filter(definition => definition.templateComponent.componentType === filter);
    }
    static { this.ɵfac = function ComponentTypeFilterPipe_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ComponentTypeFilterPipe)(); }; }
    static { this.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "componentTypeFilter", type: ComponentTypeFilterPipe, pure: false }); }
}
