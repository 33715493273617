import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppConfig } from '../shared/app-config';
import { environment } from '../../environments/environment';
import { ErrorDataService } from './error-data.service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TtsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    getTts(text, outputFormat, locale, voiceId, textType = 'ssml', outputType = 'audio_stream') {
        const urlService = AppConfig.urlTTS;
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Accept', 'audio/mpeg');
        const bodyRequest = {
            source_app: AppConfig.ttsSourceApp,
            text: text,
            text_type: textType,
            output_format: outputFormat,
            output_type: outputType,
            locale: locale,
            voice_id: voiceId
        };
        return this.httpClient.post(environment.contentLibraryApiBaseUrl + urlService, bodyRequest, { responseType: 'blob', headers: headers }).pipe(catchError((err) => {
            const message = `In TtsService.getTts(${locale},${outputFormat}). ${err.message}`;
            if (err instanceof HttpErrorResponse) {
                const $errBlobReader = Observable.create((observer) => {
                    const fr = new FileReader();
                    const errorBlob = err.error;
                    fr.readAsText(errorBlob, 'utf8');
                    fr.onloadend = () => {
                        const errMsg = JSON.parse(typeof fr.result === 'string' ? fr.result : '').message;
                        const msg = `In TtsService.getTts(${locale},${outputFormat}). ${errMsg}`;
                        observer.error(ErrorDataService.overLoadError(err, msg));
                    };
                    fr.onerror = (blobReadError) => {
                        observer.error(blobReadError);
                    };
                    fr.onabort = () => {
                        observer.error('aborted');
                    };
                });
                return $errBlobReader;
            }
            else {
                return throwError(ErrorDataService.overLoadError(err, message));
            }
        }));
    }
    static { this.ɵfac = function TtsService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TtsService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TtsService, factory: TtsService.ɵfac, providedIn: 'root' }); }
}
