import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retryWhen, timeout } from 'rxjs/operators';
import { AppConfig } from '../app-config';
import { factory } from '../app-logging';
import * as HttpStatus from 'http-status-codes';
import { RedirectService } from '../../services/redirect.service';
import { genericRetryStrategy } from '../utils/rxjs-utils';
import * as i0 from "@angular/core";
const logger = factory.getLogger('HttpErrorInterceptor');
export class HttpErrorInterceptor {
    constructor(injector) {
        this.injector = injector;
        this.redirector = this.injector.get(RedirectService);
    }
    intercept(request, next) {
        logger.trace('Intercepted: ' + JSON.stringify(request));
        // Continue Request Execution
        return next.handle(request)
            .pipe(
        // Set a timeout
        timeout(AppConfig.requestTimeout), 
        // Retry if the error code was greater than 500
        retryWhen(genericRetryStrategy({ shouldRetry: ((err) => err.status > 500) }, 'HttpErrorInterceptor', request)), 
        // Handle errors
        catchError((error) => {
            if (error instanceof HttpErrorResponse) {
                const code = isNaN(error.status) ? NaN : error.status;
                logger.error(JSON.stringify(error));
                switch (code) {
                    case HttpStatus.FORBIDDEN:
                        this.redirector.goToErrorPage(error);
                        break;
                    case HttpStatus.UNAUTHORIZED:
                        this.redirector.goToLoginPage();
                        break;
                }
            }
            return throwError(error);
        }));
    }
    static { this.ɵfac = function HttpErrorInterceptor_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || HttpErrorInterceptor)(i0.ɵɵinject(i0.Injector)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: HttpErrorInterceptor, factory: HttpErrorInterceptor.ɵfac }); }
}
