import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import { Operator } from '../model/operator';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('OperatorProgramsService');
export class OperatorsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.operatorsLastCallError = false;
    }
    findOperators() {
        if (this.operators && !this.operatorsLastCallError) {
            logger.debug('findOperators: Cache hit.');
        }
        this.operators = (this.operators && !this.operatorsLastCallError) ? this.operators : this.fetchOperators();
        return this.operators;
    }
    fetchOperators() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlOperators).pipe(map(response => {
            const operators = [];
            for (const operator of response['data']) {
                if (operator.hasOwnProperty('id')) {
                    if (operator.hasOwnProperty('display_label')) {
                        operators.push(new Operator(operator.id, operator.display_label, operator.description, operator.status));
                    }
                    else {
                        logger.error(`Operator display label is missing. Ignoring operator item from response ${operator.id}`);
                    }
                }
                else {
                    logger.error(`Operator id is missing. Ignoring operator item from response ${operator}`);
                }
            }
            logger.debug(`findOperators: ${operators.length} operators fetched from REST`);
            this.operatorsLastCallError = false;
            return operators.filter((e) => !!e.displayLabel).sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
        }), catchError((err) => {
            const message = `In OperatorsService.findOperators().`;
            this.operatorsLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    static { this.ɵfac = function OperatorsService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || OperatorsService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: OperatorsService, factory: OperatorsService.ɵfac, providedIn: 'root' }); }
}
