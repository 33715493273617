import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppConfig } from '../shared/app-config';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import { Product } from '../model/product';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('OperatorProgramsService');
export class ProgramProductsService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        // private productsWithProgram: Observable<Product[]>;
        this.productsLastCallError = false;
    }
    findProducts() {
        if (this.products && !this.productsLastCallError) {
            logger.debug('findProducts: Cache hit.');
        }
        else {
            this.products = this.fetchProducts();
        }
        return this.products;
    }
    fetchProducts() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlProducts).pipe(map(response => {
            const result = [];
            for (const product of response['data']) {
                if (product.hasOwnProperty('program_id')) {
                    const displayLabel = product.display_label || '';
                    result.push(Product.newInstance(product.id, product.program_id, displayLabel, product.status, product.timezone, product.timezone_short, product.timezone_long));
                }
                else {
                    logger.warn(`Product program_id is missing. Ignoring product item from response ${product}`);
                }
            }
            logger.debug(`findProducts: ${result.length} products fetched from REST`);
            this.productsLastCallError = false;
            return result.sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
        }), catchError((err) => {
            const message = `In ProgramProductsService.findProducts().`;
            this.productsLastCallError = true;
            return throwError(ErrorDataService.overLoadError(err, message));
        }), publishReplay(1), refCount());
    }
    getProductsWithProgram(products, programs) {
        const result = [];
        for (const product of products) {
            const program = programs.find(prg => prg.id === product.program.id);
            if (program) {
                product.program = program;
                result.push(product);
            }
        }
        return result;
    }
    /*public findProductsWithProgram(products: Program[]): Observable<Product[]> {
      if (this.products && !this.productsLastCallError && this.productsWithProgram) {
        logger.debug('findProductsWithProgram: Cache hit.');
      } else {
        this.productsWithProgram = this.fetchProductsWithProgram(products);
      }
  
      return this.productsWithProgram;
    }*/
    findProductsWithProgram(programs) {
        return new Observable(subscriber => {
            let result;
            this.findProducts().subscribe(products => {
                result = this.getProductsWithProgram(products, programs);
                subscriber.next(result);
                subscriber.complete();
            });
        });
    }
    sortProducts(products) {
        return products.sort((a, b) => {
            let compResult = a.program.operator.displayLabel.localeCompare(b.program.operator.displayLabel);
            if (compResult === 0) {
                compResult = a.program.displayLabel.localeCompare(b.program.displayLabel);
                if (compResult === 0) {
                    compResult = a.displayLabel.localeCompare(b.displayLabel);
                }
            }
            return compResult;
        });
    }
    static { this.ɵfac = function ProgramProductsService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || ProgramProductsService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ProgramProductsService, factory: ProgramProductsService.ɵfac, providedIn: 'root' }); }
}
