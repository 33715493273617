import { TemplateComponentType } from '../model/template-component-type';
import { AppConfig } from '../shared/app-config';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { factory } from '../shared/app-logging';
import { ErrorDataService } from './error-data.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const logger = factory.getLogger('TemplateComponentTypesService');
export class TemplateComponentTypesService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    findTemplateComponentTypes() {
        return this.httpClient.get(environment.contentLibraryApiBaseUrl + AppConfig.urlTemplateDeliveryPaths).pipe(map(response => {
            const componentTypes = [];
            for (const item of response['data']) {
                componentTypes.push(new TemplateComponentType(item.component_type));
            }
            return componentTypes;
        }), catchError((err) => {
            const message = `In TemplateComponentTypesService.findTemplateComponentTypes().`;
            return throwError(ErrorDataService.overLoadError(err, message));
        }));
    }
    static { this.ɵfac = function TemplateComponentTypesService_Factory(__ngFactoryType__) { return new (__ngFactoryType__ || TemplateComponentTypesService)(i0.ɵɵinject(i1.HttpClient)); }; }
    static { this.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplateComponentTypesService, factory: TemplateComponentTypesService.ɵfac, providedIn: 'root' }); }
}
